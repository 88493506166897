export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "类型", prop: "type", slots: "type", width: 170 },
    { label: "ID", prop: "accountID", width: 170 },
    { label: "昵称", prop: "name", width: 170 },
    { label: "电话", prop: "phone", width: 170 },
    { label: "头像", prop: "img", slots: "img", width: 170 },
    { label: "设置推荐", prop: "recommend", slots: "recommend", width: 170 },
    {
      label: "创建时间",
      prop: "createTime",
      width: 200,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 170,
      slots: "operation",
    },
  ];
}
