import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function selectAuthor(data) {
  return request({
    url: `/admin/author/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addAuthor(data) {
  return request({
    url: `/admin/author/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updateAuthor(data) {
  return request({
    url: `/admin/author/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deleteAuthor(data) {
  return request({
    url: `/admin/author/delete`,
    method: "POST",
    data,
  });
}

/* 设置推荐 */
export function updateRecommend(data) {
  return request({
    url: `/admin/author/updateRecommend`,
    method: "POST",
    data,
  });
}
